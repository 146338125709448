// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/circleci/project/client/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cafe-list-js": () => import("/home/circleci/project/client/src/pages/cafe-list.js" /* webpackChunkName: "component---src-pages-cafe-list-js" */),
  "component---src-pages-cafe-map-js": () => import("/home/circleci/project/client/src/pages/cafe-map.js" /* webpackChunkName: "component---src-pages-cafe-map-js" */),
  "component---src-pages-cafe-js": () => import("/home/circleci/project/client/src/pages/cafe.js" /* webpackChunkName: "component---src-pages-cafe-js" */),
  "component---src-pages-index-js": () => import("/home/circleci/project/client/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

